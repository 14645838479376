import React from 'react';
import { Checkbox, FloatingDropdown } from 'wix-ui-tpa/cssVars';
import type { FloatingDropdownOptionProps } from 'wix-ui-tpa';
import { classes } from './FilterDropdown.st.css';
import { FilterOption } from '../../../../types/types';

export type FilterDropdownProps = {
  label: string;
  options: FilterOption[];
  onChange: (selectedOption: FilterOption) => void;
  'data-hook'?: string;
};

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  label,
  onChange,
  options: filterOptions,
  'data-hook': dataHook,
}) => {
  const options: FloatingDropdownOptionProps[] = filterOptions.map(
    ({ id, value, selected }) => ({
      value,
      id,
      icon: (
        <Checkbox
          checked={selected}
          onChange={({ checked }) => onChange({ id, value, selected: checked })}
        />
      ),
    }),
  );

  return (
    <div className={classes.root}>
      <FloatingDropdown
        removeElementMinWidth
        label={label}
        onChange={onChange}
        options={options}
        data-hook={dataHook}
      />
    </div>
  );
};
