import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  IStyleParam,
} from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { generateCustomCssVars } from './stylesParamsUtils';
import {
  DisplayStyles,
  StickyStyles,
} from '../../utils/settings/useStyleSettings';

export type IStylesParams = IStyleParamsBoolean &
  IStylesParamsNumbers &
  IStyleParamsColors &
  IStyleParamsFonts &
  IStyleParamsDisplay &
  IStyleParamsSticky;

export type IStyleParamsBoolean = {};

export type IStyleParamsFonts = {
  headerTitleFont: StyleParamType.Font;
  datePickerDatesFont: StyleParamType.Font;
  filtersFont: StyleParamType.Font;
};

export type IStylesParamsNumbers = {
  borderWidth: StyleParamType.Number;
  widgetCornerRadius: StyleParamType.Number;
};

export type IStyleParamsColors = {
  widgetBackgroundColor: StyleParamType.Color;
  borderOpacityAndColor: StyleParamType.Color;
  headerTitleColor: StyleParamType.Color;
  datePickerDatesColor: StyleParamType.Color;
  selectionAndHighlightColor: StyleParamType.Color;
  datePickerDisabledColor: StyleParamType.Color;
  filtersColor: StyleParamType.Color;
};

export type IStyleParamsDisplay = {
  loadMoreButtonVisibility: StyleParamType.String;
};

export type IStyleParamsSticky = {
  headerSticky: StyleParamType.String;
  dateSticky: StyleParamType.String;
};

export const displayStyleParamsDefinitions: {
  [key in keyof IStyleParamsDisplay]: IStyleParam;
} = {
  loadMoreButtonVisibility: {
    type: StyleParamType.String,
    getDefaultValue: () => DisplayStyles.BLOCK,
  },
};

export const stickyStyleParamsDefinitions: {
  [key in keyof IStyleParamsSticky]: IStyleParam;
} = {
  headerSticky: {
    type: StyleParamType.String,
    getDefaultValue: () => StickyStyles.STICKY,
  },
  dateSticky: {
    type: StyleParamType.String,
    getDefaultValue: () => StickyStyles.STICKY,
  },
};

export const styleParamsDefinitions: {
  [key in keyof IStylesParams]: IStyleParam;
} = {
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  widgetCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  widgetBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  borderOpacityAndColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.2),
  },
  headerTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 32 }),
  },
  headerTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  datePickerDatesFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  datePickerDatesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  selectionAndHighlightColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  datePickerDisabledColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3', 0.5),
  },
  filtersFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  filtersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  ...displayStyleParamsDefinitions,
  ...stickyStyleParamsDefinitions,
};

export default createStylesParams<IStylesParams>(styleParamsDefinitions);

export const customCssVars: CustomCssVarsFn = (args) =>
  generateCustomCssVars(args);
