import React from 'react';
import { Divider } from 'wix-ui-tpa/cssVars';
import { DaySection } from '../../../../shared/components/Agenda/DaySection/DaySection';
import { classes } from './Body.st.css';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';

export const Body: React.FC = () => {
  const { agendaViewModel } = useWidgetViewModel();

  return agendaViewModel ? (
    <div className={classes.root}>
      {agendaViewModel.days.map((day, index) => (
        <React.Fragment key={index}>
          <DaySection {...day} />
          <Divider className={classes.dayDivider} />
        </React.Fragment>
      ))}
    </div>
  ) : null;
};
