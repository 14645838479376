import React from 'react';
import { TimeSlot } from '@wix/ambassador-bookings-availability-v2-time-slot/types';
import { classes } from './WeeklyDatepicker.st.css';
import { WeeklyDatePicker } from 'wix-ui-tpa/cssVars';

export interface WeeklyDatepickerProps {
  availableDates?: TimeSlot[];
  selectedDate?: Date;
}

export const WeeklyDatepicker: React.FC<WeeklyDatepickerProps> = ({}) => {
  return (
    <div className={classes.root}>
      <WeeklyDatePicker removeOuterPadding onChange={() => {}} />
    </div>
  );
};
