import React from 'react';
import { classes } from './Header.st.css';
import { Filters } from '../../../../shared/components/Filters/Filters';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';
import { WeeklyDatepicker } from '../../../../shared/components/WeeklyDatepicker';
import { Title } from '../../../../shared/components/Title';

export const Header: React.FC = () => {
  const { titleViewModel, filtersViewModel } = useWidgetViewModel();

  return (
    <div className={classes.root}>
      <Title titleViewModel={titleViewModel} />
      <div className={classes.filtersContainer}>
        <div className={classes.weeklyDatePicker}>
          <WeeklyDatepicker />
        </div>

        <div className={classes.filters}>
          <Filters filtersViewModel={filtersViewModel} />
        </div>
      </div>
    </div>
  );
};
