import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { classes } from './Title.st.css';
import { TitleViewModel } from '../../../components/DailyAgenda/viewModel/titleViewModel/titleViewModel';

export interface TitleProps {
  titleViewModel?: TitleViewModel;
}

export const Title: React.FC<TitleProps> = ({ titleViewModel }) => {
  const { titleTagName, title } = titleViewModel || {};

  return (
    <div className={classes.root}>
      <Text className={classes.headerTitle} tagName={titleTagName}>
        {title}
      </Text>
    </div>
  );
};
