import React from 'react';
import { AgendaItem } from './AgendaItem/AgendaItem';
import { st, classes } from './DaySection.st.css';
import { Divider, Text } from 'wix-ui-tpa/cssVars';
import { DaySectionViewModel } from '../../../../components/DailyAgenda/viewModel/agendaViewModel/agendaViewModel';

export const DaySection: React.FC<DaySectionViewModel> = ({
  date,
  agendaItems,
}) => {
  return (
    <section className={classes.root}>
      <div
        className={st(classes.dateContainer, classes.listItem)}
        role="presentation"
      >
        <Text>{date}</Text>
      </div>
      <ul className={classes.slotList}>
        {agendaItems.map((item, index) => (
          <li className={classes.slotContainer} key={index}>
            {index > 0 && <Divider className={classes.agendaItemDivider} />}
            <AgendaItem {...item} />
          </li>
        ))}
      </ul>
    </section>
  );
};
