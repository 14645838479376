import React from 'react';
import { classes, st } from './AgendaItem.st.css';
import {
  Avatar,
  AvatarSize,
  Button,
  ButtonSize,
  Text,
  ThumbnailImage,
} from 'wix-ui-tpa/cssVars';
import { useDailyAgendaActions } from '../../../../../components/DailyAgenda/hooks/useDailyAgendaActions';
import { AgendaItemViewModel } from '../../../../../components/DailyAgenda/viewModel/agendaViewModel/agendaViewModel';

export const AgendaItem: React.FC<AgendaItemViewModel> = ({
  serviceName,
  serviceImage,
  startTime,
  duration,
  staffName,
  staffImage,
  location,
  spots,
  price,
  buttonText,
  disabled,
  slotId,
}) => {
  const { onSlotClick } = useDailyAgendaActions();
  return (
    <div className={classes.root}>
      <ThumbnailImage
        height={80}
        width={80}
        {...serviceImage}
        className={classes.serviceImage}
      />

      <div className={st(classes.content, classes.colWrapper)}>
        <div className={st(classes.colWrapper, classes.col, classes.col3)}>
          <div className={st(classes.col2Wrapper, classes.col)}>
            <Text className={classes.col2}>{serviceName}</Text>
            <div className={classes.col2}>
              <Text className={classes.startTime}>{startTime}</Text>
              <Text className={classes.duration}>{duration}</Text>
            </div>
          </div>
          <div className={st(classes.col2Wrapper, classes.col)}>
            <div className={classes.staff}>
              <Avatar
                className={classes.staffImage}
                size={AvatarSize.small}
                src={staffImage}
                name={staffName}
              />
              <Text className={classes.staffName}>{staffName}</Text>
            </div>
            <Text className={classes.location}>{location}</Text>
          </div>
        </div>
        <div className={st(classes.colWrapper, classes.col, classes.colNoWrap)}>
          <div className={st(classes.col2Wrapper, classes.col)}>
            <Text className={classes.col2}>{price}</Text>
            <Text className={classes.col2}>{spots}</Text>
          </div>
          <div className={st(classes.col, classes.buttonWrapper)}>
            <Button
              onClick={() => onSlotClick(slotId)}
              disabled={disabled}
              className={classes.button}
              size={ButtonSize.medium}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
