import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { DailyAgendaActions } from '../actions/actions';
import { DailyAgendaActionsProvider } from '../hooks/useDailyAgendaActions';
import { Widget as WidgetComponent } from './Widget';
import { WidgetViewModelProvider } from '../hooks/useWidgetViewModel';
import { WidgetViewModel } from '../viewModel/viewModel';

const Widget: FC<
  WidgetProps<{
    viewModel: WidgetViewModel;
    actions: DailyAgendaActions;
  }>
> = ({ actions, viewModel }) => {
  return (
    <WidgetViewModelProvider value={viewModel}>
      <DailyAgendaActionsProvider value={actions}>
        <WidgetComponent />
      </DailyAgendaActionsProvider>
    </WidgetViewModelProvider>
  );
};

export default Widget;
